
.main-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.main-tab {
    font-size: 16px;
    line-height: 1;
    padding-bottom: 14px;
    border-bottom: 1px solid #F1F5FF;
}
.a-link {
    color: inherit;
}
